<template>
  <div class="change-password">
    <div class="change-password__form">
      <ui-input
        :label="$t('enter-old-password')"
        show-password
        v-model="form.oldPassword"
        :error="$v.form.oldPassword.$anyError || errorPassword"
        :error-text="errorPassword ? $t('incorrect-password') : $t('fill-in-the-field')"
        @input="errorPassword = false"
      />
      <ui-input
        :label="$t('enter-new-password')"
        show-password
        v-model="form.newPassword"
        :error="$v.form.newPassword.$anyError"
        :error-text="getNewPasswordError"
      />
      <ui-input
        :label="$t('confirm-new-password')"
        show-password
        v-model="form.confirmNewPassword"
        :error="$v.form.confirmNewPassword.$anyError"
        :error-text="getRepeatPasswordErrorText"
      />
      <div class="change-password__form-line"></div>
    </div>
    <div class="change-password__action">
      <ui-button @click="submit">
        {{ $t('change-password-1') }}
      </ui-button>
      <ui-button color="outline">
        {{ $t('cancel-1') }}
      </ui-button>
    </div>

    <ui-alert v-model="visibleSuccessAlert" @input="closeSuccessModal">
      <div class="ui-alert__title">{{ $t('password-changed-successfully') }}</div>
      <ui-button @click="closeSuccessModal">{{ $t('continue') }}</ui-button>
    </ui-alert>
  </div>
</template>

<script>
import { required, sameAs, minLength  } from 'vuelidate/lib/validators'
import UiInput from "@/components/ui/UiInput";
import UiButton from "@/components/ui/UiButton";
import {mapActions, mapMutations} from "vuex";
import UiAlert from "@/components/ui/UiAlert";
import {deleteCookie} from "@/utils/cookie";
export default {
  name: "ChangePassword",
  components: {UiAlert, UiButton, UiInput },

  data() {
    return {
      visibleSuccessAlert: false,
      errorPassword: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }
    }
  },

  validations() {
    return {
      form: {
        oldPassword: {
          required,
        },
        newPassword: {
          required,
          minLength: minLength(8)
        },
        confirmNewPassword: {
          required,
          sameAs: sameAs('newPassword'),
          minLength: minLength(8)
        }
      }
    }
  },

  computed: {
    getNewPasswordError() {
      return !this.$v.form.newPassword.required ? this.$t('fill-in-the-field') : '' ||
      !this.$v.form.newPassword.minLength ? this.$t('error-short-password') : ''
    },
    getRepeatPasswordErrorText() {
      return !this.$v.form.confirmNewPassword.required ? this.$t('fill-in-the-field') : '' ||
      !this.$v.form.confirmNewPassword.sameAs ? this.$t('error-password-do-not-match') : '' ||
      !this.$v.form.confirmNewPassword.minLength ? this.$t('error-short-password') : ''
    }
  },

  methods: {
    ...mapMutations([
       'setAuth'
    ]),
    ...mapActions([
        'changePassword'
    ]),
    submit() {
      this.$v.form.$touch()
      if(!this.$v.form.$anyError) {
        this.changePassword({
          "oldPassword": this.form.oldPassword,
          "newPassword": this.form.newPassword,
        }).then(() => {
          this.visibleSuccessAlert = true
        }).catch(() => {
          this.errorPassword = true
        })
      }
    },
    closeSuccessModal() {
      this.setAuth(false)
      deleteCookie('authToken')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.change-password {
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;
  max-width: 434px;
  width: 100%;
  padding: 40px 34px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: max-content;
  margin-left: 250px;

  @media (max-width: 992px) {
    padding: 30px 12px;
    max-width: 100%;
    margin-left: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-line {
      border-bottom: 1px solid #F5F5F5;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
